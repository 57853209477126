import React from "react"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import {
  Color,
  FontFamily,
  YellowGridTheme,
} from "../../../../../data/style/variables"
import arrowSvg from "../../../../../images/SVG/Arrow 8 (1).svg"
import { Bold } from "../../../../general/Bold/Bold"
import {
  Paragraph,
  TitleH2,
  TitleH3,
  Typography,
  TypographyTypes,
} from "../../../../general/Typography/Typography"
import {
  ArrowElement,
  ContainerElement,
  WorkflowIndexContainer,
  WorkflowItemContainer,
} from "./styled"

export const Departments: React.FC = () => {
  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "auto",
          ...YellowGridTheme,
          detailTextColor: Color.DarkGray,
          spaced: true,
          noPaddingBottom: true,
        }}
        title={
          <TitleH2 color={Color.Black}>
            U denkt dat u uw processen niet kunt automatiseren?{" "}
            <Bold>Daag ons uit!</Bold>
          </TitleH2>
        }
      >
        <Section>
          <WorkflowItemContainer>
            <TypographyAnimation>
              <TitleH3 color={Color.Black}>
                We komen langs en nemen samen met u het proces door.
              </TitleH3>
              <Paragraph color={Color.Black}>
                U wilt een procesuitbreiding, we nemen het samen met u door. Of
                u hebt een technisch probleem dat een oplossing vergt, samen
                zoeken we naar de beste oplossing.
              </Paragraph>
              <WorkflowIndexContainer>
                <Typography
                  fontFamily={FontFamily.RobotoMono}
                  titleType={TypographyTypes.span}
                  color={Color.Black}
                  fontSize={"2rem"}
                >
                  01
                </Typography>
              </WorkflowIndexContainer>
              <ArrowElement src={arrowSvg} />
            </TypographyAnimation>
          </WorkflowItemContainer>
        </Section>
        <Section>
          <WorkflowItemContainer>
            <TypographyAnimation index={1}>
              <TitleH3 color={Color.Black}>
                Samen bepalen we een strategie en oplossing.
              </TitleH3>
              <Paragraph color={Color.Black}>
                Na de intake komen we snel met een oplossing.Dit kan een
                softwareoplossing zijn in de vorm van een
                Desktopapplicatie, App of Website. Er kan ook sprake zijn van
                een hardware advies. Niks is te gek!
              </Paragraph>
              <WorkflowIndexContainer>
                <Typography
                  fontFamily={FontFamily.RobotoMono}
                  titleType={TypographyTypes.span}
                  color={Color.Black}
                  fontSize={"2rem"}
                >
                  02
                </Typography>
              </WorkflowIndexContainer>
              <ArrowElement src={arrowSvg} />
            </TypographyAnimation>
          </WorkflowItemContainer>
        </Section>
        <Section>
          <WorkflowItemContainer>
            <TypographyAnimation index={2}>
              <TitleH3 color={Color.Black}>
                Het project realiseren.
              </TitleH3>
              <Paragraph color={Color.Black}>
                Na het ontwerpen van de meest efficiënte oplossing
                gaan we voor u aan de slag. We maken duidelijke afspraken met u
                zodat u niet voor verrassingen komt te staan. Na goedkeuring
                gaat het project vervolgens van start.
              </Paragraph>
              <WorkflowIndexContainer>
                <Typography
                  fontFamily={FontFamily.RobotoMono}
                  titleType={TypographyTypes.span}
                  color={Color.Black}
                  fontSize={"2rem"}
                >
                  03
                </Typography>
              </WorkflowIndexContainer>
            </TypographyAnimation>
          </WorkflowItemContainer>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
