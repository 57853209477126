import React from "react"
import { Custom as CustomComponent } from "../components/pages/Custom/Custom"
import Layout from "../components/layout"
import { Color, HeroGridTheme } from "../data/style/variables"
import SEO from "../components/seo"

const Custom = () => (
  <Layout navColor={Color.White} borderColor={HeroGridTheme.lineColor}>
    <SEO
      description="We nemen de tijd om een goede strategie en businesscase te bedenken. Door Agile te werk te gaan kunnen we tijdig bijsturen."
      title="Maatwerk met een vaste prijs en een uitgebreide intake"
      lang="nl"
    />
    <CustomComponent />
  </Layout>
)

export default Custom
