import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import { RoutePath } from "../../../../../data/RoutePath"
import { Color, GrayGridTheme } from "../../../../../data/style/variables"
import { ListButton } from "../../../../general/ListButton/ListButton"
import { ContainerElement } from "./styled"

export const Projects: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      mxfPhoneImage: file(relativePath: { eq: "MXF/iphone_mockup1.png" }) {
        childImageSharp {
          sizes(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "auto",
          ...GrayGridTheme,
          detailTextColor: Color.DarkGray,
          spaced: true,
        }}
        detailText={"OP MAAT PROJECT"}
      >
        <Section>
          <div>
            <img
              width={375}
              src={data.mxfPhoneImage.childImageSharp.sizes.src}
            />
          </div>
        </Section>
        <Section
          sectionElementProps={{
            justifyCenter: true,
            noPaddingTop: true,
            grow: 2,
          }}
        >
          <TypographyAnimation>
            <ListButton
              title={"Een selectie website en app voor Ned Air."}
              content="Onze apps en websites draaien op alle platformen voor zowel Android als iOS. Hierdoor kunnen we apps realiseren die zonder esxtra kosten - voor apperatuur - gelijk in gebruik genomen kunnen worden door uw medewerkers."
              path={RoutePath.PowerBi}
              color={Color.Black}
            />
          </TypographyAnimation>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
