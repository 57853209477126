import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import {
  Color,
  FontFamily,
  WhiteGridTheme,
} from "../../../../../data/style/variables"
import { Bold } from "../../../../general/Bold/Bold"
import {
  CrimsonH2,
  Paragraph,
  TitleH3,
  Typography,
  TypographyTypes,
} from "../../../../general/Typography/Typography"
import {
  ContainerElement,
  CountSpanElement,
  ImgContainerElement,
  ImgTextContainerElement,
  PhoneImgElement,
  TitleContainerElement,
  WhyListContainerElement,
  WhyListItemTextElement,
} from "./styled"

export const Why: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      mxfPhoneImage: file(
        relativePath: { eq: "thisisengineering-raeng-WjOWazUPAss-unsplash.jpg" }
      ) {
        childImageSharp {
          sizes(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "calc(100vh - 160px)",
          ...WhiteGridTheme,
          detailTextColor: Color.DarkGray,
          spaced: true,
          noPaddingBottom: true,
        }}
      >
        <Section sectionElementProps={{ justifyCenter: true, noPadding: true }}>
          <TitleContainerElement>
            <CrimsonH2 color={Color.Black}>
              Waarom zou je jouw processen door ons laten automatiseren.
            </CrimsonH2>
            {/* <Subtitle color={Color.DarkGray}>
              Er is altijd wel een proces in uw bedrijf dat geautomatiseerd kan worden, laat ons deze processen vinden.
            </Subtitle> */}
          </TitleContainerElement>
          {/* <TitleH2 color={Color.Black}>
              Waarom zou je jouw processen door <Bold>ons</Bold> laten
              automatiseren.
            </TitleH2> */}
          <ImgTextContainerElement>
            <ImgContainerElement>
              <PhoneImgElement
                src={data.mxfPhoneImage.childImageSharp.sizes.src}
              />
            </ImgContainerElement>
            <div>
              {/* <TitleH3 color={Color.Black}>
                Meeste processen in uw bedrijf zijn te automatiseren, laat ons
                je helpen deze processen te vinden.
              </TitleH3> */}
              <Paragraph color={Color.Black}>
                Met onze ruime ervaring in software- en <Bold>electronica</Bold> ontwikkeling doorzien
                we de meest complexe processen. In de meeste gevallen verkort
                dit de project opstart- en implementatiefase en dat bespaart u
                weer tijd en geld. Zowel op <Bold>software</Bold> als <Bold>hardware</Bold> niveau kunnen we
                u adviseren. Of het nu gaat om een webapplicatie te koppelen aan
                een PLC bij u op de productievloer of het doen van metingen aan
                een product waarvan de data via een <Bold>webbouwer</Bold> inzichtelijk moet
                zijn, we willen dat graag samen met u realiseren.
              </Paragraph>
              <WhyListContainerElement>
                <WhyListItemTextElement>
                  <CountSpanElement>
                    <Typography
                      fontFamily={FontFamily.RobotoMono}
                      fontSize={"9.3rem"}
                      titleType={TypographyTypes.span}
                      color={Color.Black}
                    >
                      01
                    </Typography>
                  </CountSpanElement>
                  <Typography
                    fontFamily={FontFamily.RobotoMono}
                    titleType={TypographyTypes.p}
                    color={Color.Black}
                    fontSize={"2rem"}
                  >
                    Mobiel, tablet en desktop software.
                  </Typography>
                </WhyListItemTextElement>
                <WhyListItemTextElement>
                  <CountSpanElement>
                    <Typography
                      fontFamily={FontFamily.RobotoMono}
                      fontSize={"9.3rem"}
                      titleType={TypographyTypes.span}
                      color={Color.Black}
                    >
                      02
                    </Typography>
                  </CountSpanElement>
                  <Typography
                    fontFamily={FontFamily.RobotoMono}
                    titleType={TypographyTypes.p}
                    color={Color.Black}
                    fontSize={"2rem"}
                  >
                    Processen automatiseren.
                  </Typography>
                </WhyListItemTextElement>
                <WhyListItemTextElement>
                  <CountSpanElement>
                    <Typography
                      fontFamily={FontFamily.RobotoMono}
                      fontSize={"9.3rem"}
                      titleType={TypographyTypes.span}
                      color={Color.Black}
                    >
                      03
                    </Typography>
                  </CountSpanElement>
                  <Typography
                    fontFamily={FontFamily.RobotoMono}
                    titleType={TypographyTypes.p}
                    color={Color.Black}
                    fontSize={"2rem"}
                  >
                    Microsoft, Cisco en HP partner.
                  </Typography>
                </WhyListItemTextElement>
                <WhyListItemTextElement>
                  <CountSpanElement>
                    <Typography
                      fontFamily={FontFamily.RobotoMono}
                      fontSize={"9.3rem"}
                      titleType={TypographyTypes.span}
                      color={Color.Black}
                    >
                      04
                    </Typography>
                  </CountSpanElement>
                  <Typography
                    fontFamily={FontFamily.RobotoMono}
                    titleType={TypographyTypes.p}
                    color={Color.Black}
                    fontSize={"2rem"}
                  >
                    Levenslange support.
                  </Typography>
                </WhyListItemTextElement>
              </WhyListContainerElement>
            </div>
          </ImgTextContainerElement>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
