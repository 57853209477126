import TransitionLink from "gatsby-plugin-transition-link"
import React from "react"
import { Color, FontWeight } from "../../../data/style/variables"
import { CtaArrow, Rotations, Sizes } from "../CtaArrow/CtaArrow"
import {
  Paragraph,
  TitleH3,
  Typography,
  TypographyTypes,
} from "../Typography/Typography"
import {
  ButtonElement,
  ContainerElement,
  ContentElement,
  TitleElement,
} from "./styled"

type ListButtonProps = {
  title?: string
  content?: string
  path: string
  color: Color
  onlyArrow?: boolean
}

export const ListButton: React.FC<ListButtonProps> = ({
  title,
  content,
  path,
  color,
  onlyArrow,
}) => {
  return (
    <ContainerElement>
      <TitleElement>
        <TitleH3 color={color}>{title}</TitleH3>
      </TitleElement>
      <ContentElement>
        <Paragraph fontWeight={FontWeight.Regular} color={color}>
          {content}
        </Paragraph>
      </ContentElement>
      <TransitionLink to={path} exit={{ length: 1 }} entry={{ delay: 1 }}>
        <ButtonElement>
          {!onlyArrow && (
            <>
              <Typography
                titleType={TypographyTypes.h3}
                color={color}
                fontSize={"1.6rem"}
                fontSizeMobile={"1.2rem"}
              >
                Meer lezen
              </Typography>
              <CtaArrow
                size={Sizes.small}
                rotation={Rotations.right}
                color={color}
              />
            </>
          )}
          {onlyArrow && (
            <CtaArrow
              size={Sizes.medium}
              rotation={Rotations.right}
              color={color}
            />
          )}
        </ButtonElement>
      </TransitionLink>
    </ContainerElement>
  )
}
