import React from "react"
import "typeface-roboto-mono"
import { Footer } from "../Home/sections/Footer/Footer"
import { About } from "./sections/About/About"
import { Hero } from "./sections/Hero/Hero/Hero"
import { ContainerElement } from "./styled"
import { Info } from "./sections/Info/Info"
import { Why } from "./sections/Why/Why"
import { Projects } from "./sections/Projects/Projects"
import { Departments } from './sections/Departments/Departments';

export const Custom: React.FC = () => {
  return (
    <ContainerElement>
      <Hero />
      <About />
      {/* <Info/> */}
      <Why />
      <Projects />
      <Departments/>
      <Footer spacing/>
    </ContainerElement>
  )
}
