import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Color } from "../../../../../../data/style/variables"
import { ProductHero } from "../../../../../general/ProductHero/ProductHero"
import { ContainerElement } from "./styled"

export const Hero: React.FC = ({ ...props }) => {
  const imageData = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "shutterstock_371531611.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <ProductHero
        title="Maatwerk met een vaste prijs en een uitgebreide intake"
        image={imageData.heroImage.childImageSharp.fluid.src}
        background={Color.Black}
        technicalDetails="Vaste prijs - intake - koffie - taart"
        infoTexts={["Strategie vastleggen","Voortgangsrapportages","Agile"]}
      />
    </ContainerElement>
  )
}
