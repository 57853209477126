import styled from "styled-components/macro"

export const ContainerElement = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 768px) {
    height: 100%;
  }
`

export const TitleElement = styled.div`
  display: block;
  @media (max-width: 768px) {
    min-height: 100px;
  }
`

export const ContentElement = styled.div`
  display: block;
  padding: 15px 0;
  @media (max-width: 1224px) {
    display: none;
  }
`

export const ButtonElement = styled.div`
  cursor: pointer;
  height: 50px;
  @media (max-width: 768px) {
    position: absolute;
    bottom: 0;
  }
`
