import styled from "styled-components/macro"

export const ContainerElement = styled.div`
  display: flex;
`

export const ArrowElement = styled.img`
  position: absolute;
  right: -50px;
  margin-top: 15px;
`

export const WorkflowItemContainer = styled.div``
export const WorkflowIndexContainer = styled.div`
  padding: 15px 0;
`

type WorkflowItemImageElementProps = {
  src: string
}

export const WorkflowItemImageElement = styled.img`
  width: 100%;
  height: auto;
  padding: 40px;
  margin-top: 35px;
`

export const WorkflowProjectContainerElement = styled.div`
  padding: 15px 0;
`

type PhoneImgElementProps = {
  src: string
}

export const PhoneImgElement = styled.div<PhoneImgElementProps>`
  width: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 426px;
  left: -130px;
`

export const ImgContainerElement = styled.div`
  margin: 25px 50px 0 0;
  @media (max-width: 1244px) {
    display: none;
  }
`

export const ImgTextContainerElement = styled.div`
  display: flex;
  height: 100%;
  @media (max-width: 1244px) {
    padding: 25px;
  }
`

export const TitleContainerElement = styled.div`
  padding: 25px;
`

export const WhyListItemTextElement = styled.div`
  position: relative;
  margin-left: 28px;
  height: 90px;
`

export const CountSpanElement = styled.span`
  position: absolute;
  left: -25px;
  top: -10px;
  opacity: 0.07;
`

export const WhyListContainerElement = styled.div`
  margin: 75px 0;
`
