import styled from "styled-components/macro"

export const ContainerElement = styled.div`
  display: flex;
`

export const ArrowElement = styled.img`
  position: absolute;
  right: -50px;
  margin-top: 15px;
`

export const WorkflowItemContainer = styled.div`
  margin-bottom: 125px;
`
export const WorkflowIndexContainer = styled.div`
  padding: 15px 0;
`

type WorkflowItemImageElementProps = {
  src: string
}

export const WorkflowItemImageElement = styled.img`
  width: 100%;
  height: auto;
  padding: 40px;
  margin-top: 35px;
`

export const WorkflowProjectContainerElement = styled.div`
  padding: 15px 0;
`
